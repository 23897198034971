$base-color: #cd2122;
$sub-color: #780202;
$toggle-color: #141414ff;

.sidebar {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
.sidebar::-webkit-scrollbar {
  display: none;
}

.basic-card {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 15px;
}

.basic-card img {
  width: 100%;
  margin-bottom: 30px;
}

.basic-card .names-card .last-name {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.basic-card::-webkit-scrollbar {
  display: none;
}
.names-card::-webkit-scrollbar {
  display: none;
}
.last-name::-webkit-scrollbar {
  display: none;
}
#item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  padding-left: 15px;

}

#item p {
  line-height: 30px;
}

#item i {
  height: 30px;
  width: 20px;
  margin-right: 10px;
  color: $base-color;
}

.names-card {
  width: 100%;
}

.basic-card .first-name {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 0;
}

.basic-card .last-name {
  font-size: 2.5rem;
  overflow-x: hidden;
  font-weight: 500;
}

.hr {
  margin-top: 0.2rem;
  margin-bottom: 0.8rem;
  border: 0;
  border-top: 3px solid $base-color;
}

.sub {
  border-top: 1px solid $base-color;
}

.social-margin {
  margin-right: 15px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

#social:first-of-type {
  padding-left: 15px;
}

.pad-15 {
  padding-left: 15px;
}

.social-icon {
  margin-bottom: 15px;
  box-sizing: border-box;
  -moz-border-radius: 138px;
  -webkit-border-radius: 138px;
  border-radius: 138px;
  border: 5px solid;
  text-align: center;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 1px;
  padding-top: 11px;
  transition: all 0.5s;
}

/* Facebook Button Styling */
.linkedin-in {
  font-size: 22px;
  padding-top: 9px;
  border-color: #0976b4;
  background-color: #0976b4;
  color: #ffffff;

}

.linkedin-in:hover {
  background-color: #ffffff;
  color: #0976b4;
}

.github {
  font-size: 22px;
  padding-top: 9px;
  border-color: #171515;
  background-color: #171515;
  color: #ffffff;

}

.github:hover {
  background-color: #ffffff;
  color: #171515;
}

.gitlab {
  font-size: 22px;
  padding-top: 9px;
  border-color: #fca326;
  background-color: #fca326;
  color: #ffffff;

}

.gitlab:hover {
  background-color: #ffffff;
  color: #fca326;
}

.twitter {
  font-size: 22px;
  padding-top: 9px;
  border-color: #00acee;
  background-color: #00acee;
  color: #ffffff;

}

.twitter:hover {
  background-color: #ffffff;
  color: #00acee;
}


.telegram {
  font-size: 22px;
  padding-top: 9px;
  border-color: #0088cc;
  background-color: #0088cc;
  color: #ffffff;

}

.telegram:hover {
  background-color: #ffffff;
  color: #0088cc;
}

.nav-link:first-of-type {
  padding-left: 0 !important;
}

.form-check-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23000'/%3E%3C/svg%3E") !important;
}

.form-check-input:checked {
  background-color: $toggle-color !important;
  border-color: #6668 !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
}

.form-check-input:focus {
  border-color: #6668 !important;
  outline: 0;
  box-shadow: none !important;
}

.section-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#subtitle {
  display: flex;
  flex-direction: column;
}

.light {
  font-weight: 300;
}

#mobile-nav {
  margin-top: 50px;
}

.fa-angle-down:hover,
.fa-angle-up:hover,
.link:hover,
.link {
  color: $base-color;
  text-decoration: none;
}

.link1 {
  color: $base-color;
  text-decoration: none;
  transition-duration: 500ms;
}

.link1:hover {
  color: $base-color;
  text-decoration: underline;
  transition-duration: 500ms;
}

.link {
  font-size: small
}

.clickable {
  cursor: pointer;
}

#desc {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

#sub-web {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

#mode-toggle {
  cursor: pointer;
}